import {
  Nav,
  Container,
  Logo,
  ContainerData,
  PhoneInfo,
  MobileBar,
} from './NavbarElement';
import { AiOutlineClose } from 'react-icons/ai';
import { FaBars } from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Navigation from '../navigation_list';
import { useLayoutEffect, useState } from 'react';
import { useEffect } from 'react';

const Navbar = () => {
  const [visible, setVisible] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);

    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    if (windowWidth > 915) {
      setVisible(false);
    }
  });

  return (
    <Nav>
      <PhoneInfo>
        <FiPhoneCall />
        <span>
          <a href="tel:+94715808080">(+94)71 5808080</a>
        </span>
      </PhoneInfo>
      <Container>
        <ContainerData>
          <Link to="/">
            <Logo
              src={require('../../assets/images/blackLogo.png')}
              alt="logo"
            />
          </Link>

          <MobileBar>
            {visible ? (
              <AiOutlineClose
                style={{ color: '#fff' }}
                onClick={() => {
                  setVisible(!visible);
                }}
              />
            ) : (
              <FaBars
                onClick={() => {
                  setVisible(!visible);
                }}
              />
            )}
          </MobileBar>

          <Navigation status={visible} />
        </ContainerData>
      </Container>
    </Nav>
  );
};
export default Navbar;
