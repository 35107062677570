import styled from 'styled-components';

export const Nav = styled.nav`
  background-color: white;
  display: grid;
  position: fixed;
  height: 90px;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  z-index: 10;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0px 2rem;
`;

export const ContainerData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-self: flex-start;
  overflow: hidden;
  @media screen and (max-width: 1452px) {
    width: 80%;
  }
  /* @media screen and (max-width: 1063px) {
    width: 70%;
  } */
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  width: clamp(40px, 10vw, 80px);
  height: auto;
  cursor: pointer;
`;

export const PhoneInfo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  margin: 0 2rem;
  font-weight: 500;
  flex-direction: row;
  background-color: #2e5447;
  height: 65px;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  font-size: clamp(1rem, 10vw, 24px);
  font-weight: 400;
  color: #fff;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;

  span {
    padding-left: 1rem;
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;
export const MobileBar = styled.div`
  display: none;
  @media screen and (max-width: 915px) {
    z-index: 1000;
    display: flex;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
