import styled from 'styled-components';

export const Soc = styled.div`
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30%;
  z-index: 998;
`;

export const Container = styled.div`
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0 15px;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 10px;
`;

export const ContainerItem = styled.div`
  margin: 20px 0;
  background-color: #2e5447;
  padding: 5px 5px;
  cursor: pointer;
  color: #fff;
`;
