import { createGlobalStyle } from 'styled-components';

export const GloableStyle = createGlobalStyle`

*{
    font-family: "Roboto", Times, serif;
}


ul{
 
    font-weight: bold;
    color: #4b4b4b;
}
`;
