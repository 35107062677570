import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import background from "../../assets/images/backgroundImage.png";
import "./galleryStyle.css";
import Footer from "../../components/footer";
import Scoial from "../../components/social_info";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Gallery = () => {
  const [galleries, setGalleries] = useState([]);
  const [fileTypeId, setFiletypeId] = useState();

  useEffect(() => {
    getGalleryData();
  }, []);

  const getGalleryData = async () => {
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(
        "https://api.wildceylonadventures.lk/api/gallery/all",
        bodyParameters
      )
      .then((response) => {
        if (response.data.success) {
          setGalleries(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  return (
    <Fragment>
      <Scoial />
      <Navbar />
      <div className="gallaryContainer">
        <div
          className="abtMeImageContainer"
          style={{ backgroundImage: `url(${background})` }}
        >
          <img
            className="abutlogo"
            src={require("../../assets/images/WildCeylon Logo_Black_BG.png")}
            alt="background"
          />
        </div>
        <div className="gllaryContentContainer">
          <div className="imageContainer">
            <h2>Image Gallery</h2>
            <div className="imagesGrid">
              {galleries !== null &&
                galleries.map((gallery) => {
                  if (gallery.file_type_id === "1") {
                    return (
                      <div className="imageContainer">
                        <img
                          src={gallery.file_path}
                          alt="Gallery"
                          className="w-[300px] hover:scale-125 duration-500 "
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>

          <div className="videoContainer">
            {galleries !== null ? (
              <>
                <h2>Video Gallery</h2>

                <div className="flex flex-row w-full vedioply">
                  {galleries.map((gallery) => {
                    if (gallery.file_type_id === "2") {
                      return (
                        <div className="w-1/5 m-4">
                          <video
                            width="600"
                            height="300"
                            controls="controls"
                            autoplay="false"
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Gallery;
