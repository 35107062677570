import React from "react";
import { Content, Container, Title, Para } from "./content_01Element";

import Card from "../../cart_item";
const Content_01 = () => {
  return (
    <Content>
      <Container>
        <Title>About Wild Ceylon</Title>
        <Para>Wild Ceylon Adventures (Pvt) Ltd. is an accredited Travel Agent, which
          operates both inbound travelling and wildlife tourism in Sri Lanka.
          <br/>
          <br/>
          <b>Wild Ceylon Adventures is dedicated for promoting tourism in Sri
          Lanka.</b>We <b>propose a novel business concept of providing our guests
          with</b> provide Comfortable Accommodations, Luxury Transportations with
          trained National Guides, Delicious Meals, Convenient Wildlife Safaris
          with learned Naturalists, well-trained Drivers and personal Butler
          service for our local and foreign guests. The company intends to
          popularise Sri Lanka and its natural resources across the globe and
          bring wildlife lovers and other travellers to Sri Lanka. We aspire to
          develop ourselves as a consummate identity for the Sri Lankan Tourism
          and Wildlife industry.
        </Para>
      </Container>
      <Card />
    </Content>
  );
};

export default Content_01;
