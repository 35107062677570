import React from "react";
import { Soc, Container, ContainerItem } from "./scoialElemenet";
import { CiFacebook, CiLinkedin } from "react-icons/ci";
import {
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiOutlineMail,
} from "react-icons/ai";
const Scoial = () => {
  return (
    <Soc>
      <Container>
        <ContainerItem>
          <a href="https://facebook.com/wildceylonadventuressrilanka" target='_blank' rel="noreferrer">
            <CiFacebook />
          </a>
        </ContainerItem>
        <ContainerItem>
          <a href="https://instagram.com/wild.ceylon.adventures" target='_blank' rel="noreferrer">
            <AiOutlineInstagram />
          </a>
        </ContainerItem>
        {/* <ContainerItem>
          <AiOutlineWhatsApp />
        </ContainerItem> */}

        {/* <ContainerItem>
          <AiOutlineMail />
        </ContainerItem>
        <ContainerItem>
          <CiLinkedin />
        </ContainerItem> */}
      </Container>
    </Soc>
  );
};

export default Scoial;
