import styled from 'styled-components';

export const InfoContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

export const SliderContainer = styled.div``;

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: 'red';
  position: absolute;
  top: 18%;
  left: 0;
  margin-top:25px;

  @media screen and (max-width: 768px) {
    margin-top: -32px;
  }
`;
