import Navbar from "../components/navbar";
import Scoial from "../components/social_info";
import Footer from "../components/footer";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Container,
  Img,
} from "../components/content_hero/contentHeaderElement";

import { FaUserFriends } from "react-icons/fa";
import "../components/card_selecting/itemSection.css";
import axios from "axios";
import {
  CheckOutContainer,
  InputContainer,
  Input,
  InputDate,
  Button,
  CheckOut,
} from "../components/checkout/checkoutElement";
import { ImLocation2 } from "react-icons/im";
import { MdMyLocation } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Packages = () => {
  const { state } = useLocation();
  const [packs, setPacks] = useState([]);
  const [parks, setParks] = useState([]);
  const [addons, setAddons] = useState([]);
  const [nationality, setNationality] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [guests, setGuests] = useState();
  const [selectedPark, setSelectedPark] = useState();
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);

  const [addonInfo, setAddonInfo] = useState({
    item: [],
    response: [],
  });

  const [isAddonOpen, setIsAddonOpen] = useState(false);
  const [addonOpenPackage, setAddonOpenPackage] = useState();
  const [addonOpenPackageId, setAddonOpenPackageId] = useState();
  const [packageTotal, setPackageTotal] = useState();
  const [seemoreData, setSeemoreData] = useState();

  const [dateCount, setDateCount] = useState(1);

  const handlechange = (e) => {
    const { value, checked } = e.target;

    const { item } = addonInfo;

    if (checked) {
      setAddonInfo({
        item: [...item, value],
      });
    } else {
      setAddonInfo({
        item: item.filter((e) => e !== value),
      });
    }
  };

  useEffect(() => {
    parkData();
    addonData();
    if (state !== null) {
      setNationality(state.nationality);
      setSelectedPark(state.selectedPark);
      setGuests(state.guests);
      setStartDate(state.startDate);
      setEndDate(state.endDate);

      getPackageDataHome();
    }
  }, []);

  const parkData = async () => {
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post("https://api.wildceylonadventures.lk/api/park/all", bodyParameters)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const addonData = async () => {
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(
        "https://api.wildceylonadventures.lk/api/addons/all",
        bodyParameters
      )
      .then((response) => {
        if (response.data.success) {
          setAddons(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };
  const getPackageDataHome = async () => {
    if (state.nationality === "1") {
      //api for search local packages from home page
      const bodyParameters = {
        park_id: state.selectedPark,
        nationality_package_id: state.nationality,
        person_count: state.guests,
      };

      await axios
        .post(
          "https://api.wildceylonadventures.lk/api/package/full/details",
          bodyParameters
        )
        .then((response) => {
          let newArray = response.data.output;
          setPacks(newArray.reverse());
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    } else if (state.nationality === "2") {
      //api for search foriegn packages from home page
      const bodyParameters = {
        nationality_package_id: state.nationality,
        person_count: state.guests,
      };

      await axios
        .post(
          "https://api.wildceylonadventures.lk/api/package/foreign/full/details",
          bodyParameters
        )
        .then((response) => {
          let newArray = response.data.output;
          setPacks(newArray.reverse());
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    }
  };

  const getPackageData = async () => {
    if (nationality === "1") {
      //api for search local packages from package page
      const bodyParameters = {
        park_id: selectedPark,
        nationality_package_id: nationality,
        person_count: guests,
      };

      await axios
        .post(
          "https://api.wildceylonadventures.lk/api/package/full/details",
          bodyParameters
        )
        .then((response) => {
          let newArray = response.data.output;
          setPacks(newArray.reverse());
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    } else if (nationality === "2") {
      //api for search foriegn packages from package page
      const bodyParameters = {
        nationality_package_id: nationality,
        person_count: guests,
      };

      await axios
        .post(
          "https://api.wildceylonadventures.lk/api/package/foreign/full/details",
          bodyParameters
        )
        .then((response) => {
          let newArray = response.data.output;
          setPacks(newArray.reverse());
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });
    }
  };

  //validate all require fields before search results of package page
  const validation = () => {
    //day counting
    const diffInDays = moment(endDate).diff(moment(startDate), "days");
    setDateCount(diffInDays);

    if (nationality !== undefined && guests !== null) {
      if (nationality === "2") {
        getPackageData();
      } else if (nationality === "1" && selectedPark !== null) {
        getPackageData();
      } else {
        toastPopup(3, "Please Fill All Required  Fields...!");
      }
    } else {
      toastPopup(3, "Please Fill All Required  Fields...!");
    }
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };
  const packageSelect = (addonInfo, pack) => {
    navigate("/package", {
      state: { addon: addonInfo, pack: pack, dateCount: dateCount },
    });
  };

  let settings = {
    infinity: false,
    speed: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: false,
    autoplaySpeed: 0,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal(data) {
    setSeemoreData(data);
    setIsOpen(true);
  }

  return (
    <>
      <Scoial />
      <Navbar />
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 overflow-y-auto">
          {/* Container to center the panel */}
          <div className="flex h-full items-center justify-center p-4">
            <Dialog.Panel className="h-[80%] w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div className="max-h-[90%] overflow-y-auto">
                <p
                  dangerouslySetInnerHTML={{
                    __html: seemoreData,
                  }}
                ></p>
              </div>

              <div className="mt-4 float-right">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
      <Container>
        <Img>
          <img
            src={require("../assets/images/WildCeylon Logo_Black_BG.png")}
            alt="log0"
            width={"200px"}
          />
        </Img>

        {/* Start Search bar */}
        <CheckOut>
          <ToastContainer />
          <CheckOutContainer>
            <InputContainer>
              <ImLocation2 />
              <Input
                value={nationality}
                onChange={(e) => {
                  setPacks([]);
                  setNationality(e.target.value);
                  sessionStorage.setItem("nationality", e.target.value);
                }}
              >
                <option value={0} disabled={true} selected>
                  Nationality
                </option>
                <option value={1}>Local</option>
                <option value={2}>Foreign</option>
              </Input>
            </InputContainer>
            {nationality === "2" ? (
              <></>
            ) : (
              <>
                <InputContainer>
                  <MdMyLocation />
                  <Input
                    value={selectedPark}
                    onChange={(e) => {
                      setSelectedPark(e.target.value);
                      sessionStorage.setItem("selectedPark", e.target.value);
                    }}
                  >
                    <option value="starter" selected>
                      Select park
                    </option>
                    {parks.map((park) => {
                      return <option value={park.id}>{park.park_name}</option>;
                    })}
                  </Input>
                </InputContainer>

                <InputContainer>
                  <BsCalendarDate />

                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      sessionStorage.setItem(
                        "startDate",
                        Moment(date).format("YYYY-MM-DD")
                      );
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="text-lg text-black mx-4"
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                  />
                </InputContainer>
                <InputContainer>
                  <BsCalendarDate />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      sessionStorage.setItem(
                        "endDate",
                        Moment(date).format("YYYY-MM-DD")
                      );
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date(new Date(startDate).getTime() + 86400000)}
                    placeholderText="End Date"
                    className="text-lg text-black mx-4"
                    dateFormat="dd/MM/yyyy"
                  />
                </InputContainer>
              </>
            )}

            <InputContainer>
              <FaUser />
              {nationality === "1" ? (
                <Input
                  value={guests}
                  onChange={(e) => {
                    setGuests(e.target.value);
                    sessionStorage.setItem("personCount", e.target.value);
                  }}
                >
                  <option value="starter" selected>
                    No Of Guests <label className="text-red-600">*</label>
                  </option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                </Input>
              ) : (
                <Input
                  value={guests}
                  onChange={(e) => {
                    setGuests(e.target.value);
                    sessionStorage.setItem("personCount", e.target.value);
                  }}
                >
                  <option value="starter" selected>
                    No Of Guests <label className="text-red-600">*</label>
                  </option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                  <option value={17}>17</option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                  <option value={21}>21</option>
                  <option value={22}>22</option>
                  <option value={23}>23</option>
                  <option value={24}>24</option>
                  <option value={25}>25</option>
                  <option value={26}>26</option>
                  <option value={27}>27</option>
                  <option value={28}>28</option>
                  <option value={29}>29</option>
                  <option value={30}>30</option>
                  <option value={31}>31</option>
                  <option value={32}>32</option>
                  <option value={33}>33</option>
                  <option value={34}>34</option>
                  <option value={35}>35</option>
                  <option value={36}>36</option>
                  <option value={37}>37</option>
                  <option value={38}>38</option>
                  <option value={39}>39</option>
                  <option value={40}>40</option>
                </Input>
              )}
            </InputContainer>

            <Button
              onClick={() => {
                validation();
              }}
            >
              Check Now
            </Button>
          </CheckOutContainer>
        </CheckOut>
      </Container>
      {/* <CardSelecting /> */}
      <div
        className="  container  cont carousel "
        style={{ marginBottom: "100px" }}
      >
        <Slider {...settings}>
          {packs !== null &&
            packs.map((pack) => {
              return (
                <div className="card-block">
                  <div className="container-card">
                    <h2
                      className="card-title"
                      dangerouslySetInnerHTML={{
                        __html: pack.package_name,
                      }}
                    ></h2>

                    <div className="card-info-title">
                      <FaUserFriends />
                      <h5 className="card-info-title-main">
                        <lable>Per Person Price</lable> &nbsp;
                        <label className="font-bold">
                          {nationality === "1" ? " LKR. " : " $. "}
                          {parseFloat(pack.pp_price * dateCount).toLocaleString(
                            navigator.language,
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </label>
                      </h5>
                    </div>
                    <div className="info-container pl-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: pack.description,
                        }}
                        className="mb-2"
                      ></div>
                      <div
                        className={
                          nationality === "1"
                            ? "hidden"
                            : "visible mt-2 w-full bg-slate-400 text-center cursor-pointer"
                        }
                        onClick={() => {
                          openModal(pack.other_description);
                        }}
                      >
                        See more
                      </div>

                      <div className="border-b-[1px] border-black w-full content-center text-center pb-2 pt-2 border-t-[1px]">
                        <strong className="price">
                          <lable> {nationality === "1" ? "LKR. " : "$ "}</lable>
                        </strong>
                        <label className="text-xl font-bold">
                          {nationality === "1"
                            ? parseFloat(
                                (pack.pp_price * guests * dateCount).toFixed(2)
                              ).toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })
                            : parseFloat(
                                (pack.pp_price * guests).toFixed(2)
                              ).toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })}
                        </label>
                      </div>
                    </div>

                    <div className="btn-container">
                      <button
                        onClick={() => {
                          if (addonOpenPackageId === pack.package_id) {
                            setIsAddonOpen(false);
                            setAddonOpenPackage(null);
                            setAddonOpenPackageId(null);
                          } else {
                            setIsAddonOpen(true);
                            setAddonOpenPackage(pack);
                            setAddonOpenPackageId(pack.package_id);
                            setPackageTotal(
                              nationality === "1"
                                ? (pack.pp_price * guests * dateCount).toFixed(
                                    2
                                  )
                                : (pack.pp_price * guests).toFixed(2)
                            );
                          }
                        }}
                      >
                        <lable>
                          {addonOpenPackageId === pack.package_id
                            ? "Unselect"
                            : "Select"}
                        </lable>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>

        {/* addon list and total bar */}
        <div
          className={
            isAddonOpen === true
              ? "visible flex flex-col items-center justify-center"
              : "hidden"
          }
        >
          {/* addon list */}
          <div className=" border-gray-200 border-1 shadow-xl rounded-xl m-3 p-1 w-fit xxs:w-full">
            <label
              className=" text-center block uppercase tracking-wide text-gray-700 text-2xl font-bold m-2"
              for="grid-last-name"
            >
              Addons
            </label>

            {addons.map((addon) => {
              return (
                <div className="info-container-item">
                  <div className="item-container-check">
                    <div className="item-container-check-data">
                      <input
                        className="m-2"
                        type="checkbox"
                        id="item"
                        name="item"
                        value={addon.title}
                        onChange={handlechange}
                      ></input>
                      <label
                        style={{
                          marginLeft: "10px",
                          fontSize: "18px",
                          fontWeight: 600,
                        }}
                      >
                        {addon.title}
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* addon list end */}
          {/* total bar */}
          <div className="flex flex-row bg-black border-1 rounded-xl m-0 bottom-0 w-full">
            <div className="flex flex-col w-full">
              <lable className="font-extrabold text-white m-2">Total</lable>
              <lable className="font-extrabold text-white m-2 xxs:text-lg">
                <lable> {nationality === "1" ? "LKR. " : " $ "}</lable>
                {parseFloat(packageTotal).toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                })}
              </lable>
            </div>
            <div className="flex w-full  justify-end py-4 pr-3">
              <button
                className="text-white font-extrabold bg-yellow-600 float-right border-black border-1 mt-0 p-2 rounded-md"
                onClick={() => {
                  packageSelect(addonInfo, addonOpenPackage);
                }}
              >
                Continue
              </button>
            </div>
          </div>
          {/* total bar end */}
        </div>
        {/* addon list and total bar end*/}
      </div>
      <Footer />
    </>
  );
};

export default Packages;
