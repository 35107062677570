import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  background-color: #2e5447;
  color: #fff;
  height: auto;

  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  justify-content: center;
  align-items: center;
`;
export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const Info_01 = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  padding-bottom: 20px;
`;

export const Info_01Item = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;

  @media screen and (max-width: 1299px) {
    text-align: center;
    justify-content: center;
  }
`;

export const Pare = styled.h5`
  padding-bottom: 15px;
  padding-left: 13px;

  @media screen and (max-width: 785px) {
    font-size: 18px;
  }
`;

export const Info_02 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 100px;

  @media screen and (max-width: 1299px) {
    justify-content: center;
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

export const Info_02Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 785px) {
    justify-content: center;
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  }
`;

export const Pare_h5 = styled.h5`
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  padding-bottom: 15px;

  @media screen and (max-width: 785px) {
    font-size: 18px;
    line-height: 20px;
  }
`;
export const Info_02Sub = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;
export const ConatinerItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: space-around;
  align-items: flex-start;

  @media screen and (max-width: 1299px) {
    flex-wrap: wrap;
    width: 80%;
  }
`;
export const SocialIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  margin-top: 20px;
  @media screen and (max-width: 1299px) {
    justify-content: center;
    align-items: center;
  }
`;

export const SocialIconItem = styled.div`
  margin-right: 40px;
  font-size: 1.2rem;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  color: #2e5447;

  @media screen and (max-width: 785px) {
    margin: 0px 10px;
  }
`;

export const IsideContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 1299px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }
`;

export const Address = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  @media screen and (max-width: 1299px) {
  }
`;

export const Info_01Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
