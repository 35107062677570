import { render } from "@testing-library/react";
import React, { Component, Fragment, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Scoial from "../../components/social_info";
import "./aboutMeStyles.css";
import background from "../../assets/images/backgroundImage.png";
import axios from "axios";
import { toast } from "react-toastify";

const About = () => {
  const [about, setAbout] = useState([]);

  useEffect(() => {
    getAboutData();
  }, []);

  const getAboutData = async () => {
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(
        "https://api.wildceylonadventures.lk/api/about/all",
        bodyParameters
      )
      .then((response) => {
        setAbout(response.data.output);
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };
  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };
  return (
    <Fragment>
      <Scoial />
      <Navbar />
      <div className="aboutMeContainer">
        <div
          className="abtMeImageContainer"
          style={{ backgroundImage: `url(${background})` }}
        >
          <img
            className="abutlogo"
            src={require("../../assets/images/WildCeylon Logo_Black_BG.png")}
            alt='logo'
          />
        </div>
        <h2 className="abtTitile">About Us</h2>
        {about?.map((ab) => {
          return (
            <div className="abutMisson">
              <div className="cardContainer">
                <img className="cardLogo" src={ab.file_path} alt='logo'/>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: ab.title,
                  }}
                ></h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: ab.description,
                  }}
                ></p>
              </div>
            </div>
          );
        })}
        {/* <h2 className="abtTitile">Our Leadership</h2>
        <div className="commentContainer">
          <div className="avatarContainer">
            <img src={require("../../assets/images/avetar1.png")} />
          </div>
          <div className="textContainer">
            <div className="headingContainer">
              <h2>Mr. Chanuka Saranga</h2>
              <p>CEO/Group Managing Director</p>
            </div>
            <div className="paraContainer">
              <p>
                Lorem ipsum dolor sit amet consectetur. Blandit gravida a id
                malesuada sed sollicitudin commodo ut. Eget lacus in
                pellentesque orci maecenas nibh ac varius dictum. Id pharetra
                ultrices arcu sed ornare convallis nisi amet. Et quam natoque
                nisi sit arcu. At a maecenas laoreet cras tellus purus id
                vestibulum non. Non pharetra id nec nec ullamcorper. Neque
                fermentum amet vitae lobortis. In et vitae congue aenean blandit
                molestie nisl turpis. Quis felis vestibulum in amet quis
                faucibus duis amet. Neque scelerisque ac sit a sed. Facilisis a
                venenatis a sed eu non aliquam ultrices. Viverra dictum sed
                sollicitudin etiam eu morbi. Nunc venenatis turpis malesuada
                euismod viverra cras vitae tellus.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Blandit gravida a id
                malesuada sed sollicitudin commodo ut. Eget lacus in
                pellentesque orci maecenas nibh ac varius dictum. Id pharetra
                ultrices arcu sed ornare convallis nisi amet. Et quam natoque
                nisi sit arcu. At a maecenas laoreet cras tellus purus id
                vestibulum non. Non pharetra id nec nec ullamcorper. Neque
                fermentum amet vitae lobortis. In et vitae congue aenean blandit
                molestie nisl turpis. Quis felis vestibulum in amet quis
                faucibus duis amet. Neque scelerisque ac sit a sed. Facilisis a
                venenatis a sed eu non aliquam ultrices. Viverra dictum sed
                sollicitudin etiam eu morbi. Nunc venenatis turpis malesuada
                euismod viverra cras vitae tellus.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Blandit gravida a id
                malesuada sed sollicitudin commodo ut. Eget lacus in
                pellentesque orci maecenas nibh ac varius dictum. Id pharetra
                ultrices arcu sed ornare convallis nisi amet. Et quam natoque
                nisi sit arcu. At a maecenas laoreet cras tellus purus id
                vestibulum non. Non pharetra id nec nec ullamcorper. Neque
                fermentum amet vitae lobortis. In et vitae congue aenean blandit
                molestie nisl turpis. Quis felis vestibulum in amet quis
                faucibus duis amet. Neque scelerisque ac sit a sed. Facilisis a
                venenatis a sed eu non aliquam ultrices. Viverra dictum sed
                sollicitudin etiam eu morbi. Nunc venenatis turpis malesuada
                euismod viverra cras vitae tellus.
              </p>
            </div>
          </div>
        </div>
        <div className="commentContainer">
          <div className="avatarContainer">
            <img src={require("../../assets/images/avatar2.png")} />
          </div>
          <div className="textContainer">
            <div className="headingContainer">
              <h2>Mr. Chanuka Saranga</h2>
              <p>CEO/Group Managing Director</p>
            </div>
            <div className="paraContainer">
              <p>
                Lorem ipsum dolor sit amet consectetur. Blandit gravida a id
                malesuada sed sollicitudin commodo ut. Eget lacus in
                pellentesque orci maecenas nibh ac varius dictum. Id pharetra
                ultrices arcu sed ornare convallis nisi amet. Et quam natoque
                nisi sit arcu. At a maecenas laoreet cras tellus purus id
                vestibulum non. Non pharetra id nec nec ullamcorper. Neque
                fermentum amet vitae lobortis. In et vitae congue aenean blandit
                molestie nisl turpis. Quis felis vestibulum in amet quis
                faucibus duis amet. Neque scelerisque ac sit a sed. Facilisis a
                venenatis a sed eu non aliquam ultrices. Viverra dictum sed
                sollicitudin etiam eu morbi. Nunc venenatis turpis malesuada
                euismod viverra cras vitae tellus.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Blandit gravida a id
                malesuada sed sollicitudin commodo ut. Eget lacus in
                pellentesque orci maecenas nibh ac varius dictum. Id pharetra
                ultrices arcu sed ornare convallis nisi amet. Et quam natoque
                nisi sit arcu. At a maecenas laoreet cras tellus purus id
                vestibulum non. Non pharetra id nec nec ullamcorper. Neque
                fermentum amet vitae lobortis. In et vitae congue aenean blandit
                molestie nisl turpis. Quis felis vestibulum in amet quis
                faucibus duis amet. Neque scelerisque ac sit a sed. Facilisis a
                venenatis a sed eu non aliquam ultrices. Viverra dictum sed
                sollicitudin etiam eu morbi. Nunc venenatis turpis malesuada
                euismod viverra cras vitae tellus.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur. Blandit gravida a id
                malesuada sed sollicitudin commodo ut. Eget lacus in
                pellentesque orci maecenas nibh ac varius dictum. Id pharetra
                ultrices arcu sed ornare convallis nisi amet. Et quam natoque
                nisi sit arcu. At a maecenas laoreet cras tellus purus id
                vestibulum non. Non pharetra id nec nec ullamcorper. Neque
                fermentum amet vitae lobortis. In et vitae congue aenean blandit
                molestie nisl turpis. Quis felis vestibulum in amet quis
                faucibus duis amet. Neque scelerisque ac sit a sed. Facilisis a
                venenatis a sed eu non aliquam ultrices. Viverra dictum sed
                sollicitudin etiam eu morbi. Nunc venenatis turpis malesuada
                euismod viverra cras vitae tellus.
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </Fragment>
  );
};

export default About;
