import React from 'react';
import { ItemContainer, Para, IconContainer } from './itemElement';

const Item = ({ p, icon }) => {
  return (
    <ItemContainer>
      <IconContainer>
        <img src={icon} alt="img" />
      </IconContainer>
      <Para>{p}</Para>
    </ItemContainer>
  );
};

export default Item;
