import React from 'react';
import {
  Content,
  Container,
  Title,
  Para,
} from '../content_02/content_02Element';

const Content_02 = () => {
  return (
    <Content>
      <Container>
        <Title>Who We Are</Title>
        <Para>
          We are a team of wildlife lovers dedicated to promoting wildlife
          tourism in Sri Lanka.
          We have identified the shortcomings faced by the
          visitors to Wildlife Parks today. Major inadequacies identified are
          food, comfort accommodation, convenient travelling, logistics and
          other important necessities. We propose a novel business concept of
          developing facilities with above conveniences to cater to the local
          and foreign visitors. We intend to develop as a consummate identity in
          the Sri Lankan Wildlife industry.
        </Para>
      </Container>
    </Content>
  );
};

export default Content_02;
