import React from "react";
import { Link } from "react-router-dom";
import {
  Content,
  Container,
  ImgContainer,
  Info_01,
  Title,
  Info_01Item,
  Pare_h5,
  Pare,
  Info_02,
  Info_02Container,
  Info_02Sub,
  ConatinerItem,
  SocialIcon,
  SocialIconItem,
  InfoContainer,
  Address,
  Info_01Container,
} from "./footerElement";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { ImLocation2 } from "react-icons/im";
import { CgFacebook } from "react-icons/cg";
import { CiMail } from "react-icons/ci";

import {
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiOutlineGooglePlus,
} from "react-icons/ai";
const Footer = () => {
  return (
    <Content>
      <Container>
        <ConatinerItem>
          <InfoContainer>
            <ImgContainer>
              <Link to="/">
                <img
                  src={require("../../assets/images/WildCeylon Logo_Black_BG.png")}
                  alt="footer logo"
                  width={"200px"}
                  height={"200px"}
                />
              </Link>
            </ImgContainer>
            <Info_01>
              <Title>Contact us</Title>
              <Info_01Container>
                <Info_01Item>
                  <BsFillTelephoneFill />
                  <Pare>+94 71 5808080</Pare>
                </Info_01Item>
                <Info_01Item>
                  <HiOutlineMail />
                  <Pare>info@wildceylonadventures.com</Pare>
                </Info_01Item>
                <Info_01Item>
                  <ImLocation2 />
                  <Address>
                    <Pare>
                      Wild Ceylon Adventures (Pvt) Ltd. 145/1, High Level Road,
                      Pannipitiya 10230, Sri Lanka
                    </Pare>
                  </Address>
                </Info_01Item>
              </Info_01Container>
            </Info_01>
          </InfoContainer>

          <Info_02>
            <Info_02Container>
              {/* <Info_02Sub>
                <Title>About Us</Title>
                <Pare_h5>
                  <Link to="/about">About</Link>
                </Pare_h5>
                <Pare_h5>
                  <Link to="/careers">Careers</Link>
                </Pare_h5>
                <Pare_h5>
                  <Link to="/careers">Business Contact</Link>
                </Pare_h5>
              </Info_02Sub> */}
              <Info_02Sub>
                <Title>Usefull Links</Title>
                <Pare_h5>
                  <Link to="/">Home</Link>
                </Pare_h5>
                <Pare_h5>
                  <Link to="/packages">Packages</Link>
                </Pare_h5>
                <Pare_h5>
                  <Link to="/gallery">Gallery</Link>
                </Pare_h5>
              </Info_02Sub>
              <Info_02Sub>
                <Title>
                  <Link to="/careers">Others</Link>
                </Title>
                <Pare_h5>
                  <Link to="/careers">Terms And Conditions</Link>
                </Pare_h5>
                <Pare_h5>
                  <Link to="/careers">Privacy Policy</Link>
                </Pare_h5>
                <Pare_h5>
                  <Link to="/contactus">Contact Us</Link>
                </Pare_h5>
              </Info_02Sub>
            </Info_02Container>
            <SocialIcon>
              <SocialIconItem>
                <a href="https://facebook.com/wildceylonadventuressrilanka" target='_blank' rel="noreferrer">
                  <CgFacebook />
                </a>
              </SocialIconItem>
              {/* <SocialIconItem>
                <Link to="/">
                  <AiOutlineTwitter />
                </Link>
              </SocialIconItem> */}
              <SocialIconItem>
                <a href="https://instagram.com/wild.ceylon.adventures" target='_blank' rel="noreferrer">
                  <AiOutlineInstagram />
                </a>
              </SocialIconItem>
              {/* <SocialIconItem>
                <Link to="/">
                  <AiOutlineGooglePlus />
                </Link>
              </SocialIconItem> */}
              {/* <SocialIconItem>
                <Link to="/">
                  <CiMail />
                </Link>
              </SocialIconItem> */}
            </SocialIcon>
          </Info_02>
        </ConatinerItem>
      </Container>
    </Content>
  );
};

export default Footer;
