import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 500px) {
    padding-top: 5rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 64px;
  font-weight: 700;
  line-height: 73px;
  letter-spacing: 1.5px;
  color: #235347;
  text-align: center;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
    line-height: 50px;
  }
`;

export const Para = styled.p`
  margin-top: 2rem;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: justify;
  color: #000;
  width: 80%;
  text-align: center;
  color: #235347;
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
    line-height: 24px;
    width: 90%;
  }
`;
