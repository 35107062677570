import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
  @media screen and (max-width: 500px) {
    padding-top: 5rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #235347;
  text-align: center;
  line-height: 73px;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
    line-height: 50px;
  }
`;

export const Para = styled.p`
  margin-top: 2rem;
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  color: #235347;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
    line-height: 20px;
  }
`;
export const ItemContainer = styled.div`
  margin-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 50px;
  justify-content: center;
  align-items: center;
`;
