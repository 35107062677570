import styled from 'styled-components';

export const ListContainer = styled.div`
  position: relative;
  width: 100%;
  transition: all 1s ease-in-out;
  @media screen and (max-width: 915px) {
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden;
    width: ${(props) => (props.status ? '75%' : '0%')};
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }
`;

export const ListContainerItem = styled.div`
  width: 100%;
`;

export const List = styled.ul`
  font-size: clamp(1rem, 10vw, 24px);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 915px) {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
  }
`;

export const ListItem = styled(List)`
  padding: 0 2rem;
  font-weight: 700;
  line-height: 28px;
  @media screen and (max-width: 1189px) {
    padding: 5px 10px;
    font-size: 20px;
  }
  @media screen and (max-width: 1063px) {
    padding: 5px 10px;
  }

  @media screen and (max-width: 915px) {
    margin: 10px 0px;
    background-color: transparent;
    width: max-content;
    height: auto;
    color: #fff;
  }
`;
