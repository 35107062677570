import styled from 'styled-components';
import img from '../../../assets/images/half-bg.png';
export const Content = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
  @media screen and (max-width: 500px) {
    padding-top: 5rem;
  }
`;

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url(${img}) no-repeat;
  background-size: cover;
  background-position: 100%;
  padding: 80px 0px;
  opacity: 1;
  filter: grayscale(30%);
  filter: brightness(80%);
  filter: blur(1);
`;

export const Title = styled.h2`
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #fff;
  text-align: center;
  line-height: 73px;

  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
    line-height: 50px;
  }
`;

export const Para = styled.p`
  margin-top: 2rem;
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  color: #fff;
  width: 90%;
  text-align: center;
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
    line-height: 25px;
  }
`;
