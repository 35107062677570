import React from 'react';

import Item from '../../item';
import {
  Content,
  Container,
  Title,
  Para,
  ItemContainer,
} from '../content_03/content_03Element';

import I01 from '../../../assets/images/flight.png';
import I02 from '../../../assets/images/adventure.png';
import I03 from '../../../assets/images/dolphin.png';
import I04 from '../../../assets/images/jeep.png';
import I05 from '../../../assets/images/dinner.png';
import I06 from '../../../assets/images/hotel-bed.png';
import I07 from '../../../assets/images/tent.png';
import I08 from '../../../assets/images/safari.png';

const Content_03 = () => {
  return (
    <Content>
      <Container>
        <Title>Our Services</Title>
        <Para>
          We can help you to arrange your stay and experience the wildlife
          within your allotted budget.
        </Para>
        <ItemContainer>
          <Item
            p="Inbound Tourism with Standard and Customized Packages"
            icon={I01}
          />
          <Item
            p="HRD & Adventure Activities"
            icon={I02}
          />
          <Item p="Whale & Dolphin Watching" icon={I03} />
          <Item p="Luxury Transportation with Professional National Guides" icon={I04} />
          <Item p="Delicious Meals including Snacks & Beverages" icon={I05} />
          <Item p="Comfortable Accommodations at selected Hotels, Villas & Bungalows with personal Butler Service" icon={I06} />
          <Item p="All the rental needs for Safari Tours & Campings" icon={I07} />
          <Item p="Wildlife Safari Tours with learned Naturalists and well-trained safari Drivers" icon={I08} />
        </ItemContainer>
      </Container>
    </Content>
  );
};

export default Content_03;
