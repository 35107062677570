import React, { Component, Fragment, useState } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { ImLocation2 } from "react-icons/im";
import Navbar from "../../components/navbar";
import background from "../../assets/images/backgroundImage.png";
import "./contactUsStyles.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Footer from "../../components/footer";
import Scoial from "../../components/social_info";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Contact = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  const addSupport = async () => {
    const data = {
      full_name: name,
      email_address: email,
      mobile_number: phone,
      title: subject,
      description: message,
    };
 
    await axios
      .post("https://api.wildceylonadventures.lk/api/support/add", data)
      .then((response) => {
    
        if (response?.data.success) {
          toastPopup(1, response.data.message);
          window.location.reload("contactus");
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
     
        toastPopup(2, error.message);
      });
  };
  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  return (
    <Fragment>
      <Scoial />
      <Navbar />

      <div
        className="cntctUsContainer"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="contentContainer">
          <div className="detailsContainer">
            <div className="details">
              <h2>Contact Us</h2>
              <div className="infoContainer">
                <BsFillTelephoneFill />
                <p>+94 71 5808080</p>
              </div>
              <div className="infoContainer">
                <HiOutlineMail />
                <p>info@wildceylonadventures.com</p>
              </div>
              <div className="infoContainer">
                <ImLocation2 />
                <p>
                  Wild Ceylon Adventures (Pvt) Ltd. 145/1,
                  <br /> High Level Road,
                  <br />
                  Pannipitiya 10230,
                  <br /> Sri Lanka
                </p>
              </div>
            </div>
            <div className="imageContainer">
              <div className="image">
                <img
                  src={require("../../assets/images/Wild Ceylon SL_Elephant_Black_BG.png")}
                />
              </div>
            </div>
          </div>
          <div className="formContainer">
            <ToastContainer />
            <div className="fieldContainer">
              <div className="textField w-full">
                <TextField
                  className="input"
                  id="outlined-required"
                  label="Name"
                  defaultValue=""
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                }}
                />
              </div>
              <div className="textField">
                <TextField
                  className="input"
                  id="outlined-required"
                  label="Email"
                  defaultValue=""
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
              
                  }}
                />
              </div>
              <div className="textField">
                <TextField
                  className="input"
                  id="outlined-required"
                  label="Phone"
                  defaultValue=""
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                 
                  }}
                />
              </div>
              <div className="textField">
                <TextField
                  className="input"
                  id="outlined-required"
                  label="Subject"
                  defaultValue=""
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
       
                  }}
                />
              </div>
              <div className="textField">
                <TextField
                  className="input"
                  id="outlined-multiline-static"
                  label="Message"
                  multiline
                  rows={4}
                  defaultValue=""
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                   
                  }}
                />
              </div>
              <div className="textField">
                <Button
                  className="sendBtn"
                  disableElevation
                  onClick={addSupport}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default Contact;
