import React, { useEffect, useState } from "react";
import {
  CheckOutContainer,
  InputContainer,
  Input,
  Button,
  CheckOut,
} from "./checkoutElement";
import { ImLocation2 } from "react-icons/im";
import { MdMyLocation } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Checkout = () => {
  const navigate = useNavigate();
  const [parks, setParks] = useState([]);
  const [nationality, setNationality] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [guests, setGuests] = useState(null);
  const [selectedPark, setSelectedPark] = useState(null);

  useEffect(() => {
    parkData();
  }, []);

  const parkData = async () => {
    const bodyParameters = {
      status: 1,
    };

    await axios
      .post("https://api.wildceylonadventures.lk/api/park/all", bodyParameters)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const packagePage = (
    nationality,
    selectedPark,
    startDate,
    endDate,
    guests
  ) => {
    navigate("/packages", {
      state: {
        nationality: nationality,
        selectedPark: selectedPark,
        startDate: startDate,
        endDate: endDate,
        guests: guests,
      },
    });
  };

  //validate all require fields before navigate packages page to search results
  const validation = () => {
    if (nationality !== undefined && guests !== null) {
      if (nationality === "2") {
        packagePage(nationality, selectedPark, startDate, endDate, guests);
      } else if (nationality === "1" && selectedPark !== null) {
        packagePage(nationality, selectedPark, startDate, endDate, guests);
      } else {
        toastPopup(3, "Please Fill All Required  Fields...!");
      }
    } else {
      toastPopup(3, "Please Fill All Required  Fields...!");
    }
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  return (
    <CheckOut>
      <CheckOutContainer>
        <InputContainer>
          <ImLocation2 />
          <Input
            value={nationality}
            onChange={(e) => {
              setNationality(e.target.value);
            }}
          >
            <option value={0} disabled={true} selected>
              Nationality <label className="text-red-600">*</label>
            </option>
            <option value={1}>Local</option>
            <option value={2}>Foreign</option>
          </Input>
        </InputContainer>
        {nationality === "2" ? (
          <></>
        ) : (
          <>
            <InputContainer>
              <MdMyLocation />
              <Input
                value={selectedPark}
                onChange={(e) => {
                  setSelectedPark(e.target.value);
                  sessionStorage.setItem("selectedPark", e.target.value);
                }}
              >
                <option selected disabled={true}>
                  Select park <label className="text-red-600">*</label>
                </option>

                {parks.map((park) => {
                  return <option value={park.id}>{park.park_name}</option>;
                })}
              </Input>
            </InputContainer>

            <InputContainer>
              <BsCalendarDate />
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  sessionStorage.setItem(
                    "startDate",
                    Moment(date).format("YYYY-MM-DD")
                  );
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                className="text-lg text-black mx-4"
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
              />
            </InputContainer>

            <InputContainer>
              <BsCalendarDate />
              <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      sessionStorage.setItem(
                        "endDate",
                        Moment(date).format("YYYY-MM-DD")
                      );
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date(new Date(startDate).getTime() + 86400000)}
                    placeholderText="End Date"
                    className="text-lg text-black mx-4"
                    dateFormat="dd/MM/yyyy"
                  />
            </InputContainer>
          </>
        )}
        <InputContainer>
          <FaUser />
          {nationality === "1" ? (
            <Input
              value={guests}
              onChange={(e) => {
                setGuests(e.target.value);
                sessionStorage.setItem("personCount", e.target.value);
              }}
            >
              <option selected disabled={true}>
                No Of Guests <label className="text-red-600">*</label>
              </option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
            </Input>
          ) : (
            <Input
              value={guests}
              onChange={(e) => {
                setGuests(e.target.value);
                sessionStorage.setItem("personCount", e.target.value);
              }}
            >
              <option selected disabled={true}>
                No Of Guests <label className="text-red-600">*</label>
              </option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
              <option value={17}>17</option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
              <option value={21}>21</option>
              <option value={22}>22</option>
              <option value={23}>23</option>
              <option value={24}>24</option>
              <option value={25}>25</option>
              <option value={26}>26</option>
              <option value={27}>27</option>
              <option value={28}>28</option>
              <option value={29}>29</option>
              <option value={30}>30</option>
              <option value={31}>31</option>
              <option value={32}>32</option>
              <option value={33}>33</option>
              <option value={34}>34</option>
              <option value={35}>35</option>
              <option value={36}>36</option>
              <option value={37}>37</option>
              <option value={38}>38</option>
              <option value={39}>39</option>
              <option value={40}>40</option>
            </Input>
          )}
        </InputContainer>

        <Button
          onClick={() => {
            validation();
          }}
        >
          Check Now
        </Button>
      </CheckOutContainer>
    </CheckOut>
  );
};

export default Checkout;
