import React from 'react';
import Footer from '../components/footer';
import Hero from '../components/hero';
import Content_01 from '../components/home/content_01';
import Content_02 from '../components/home/content_02';
import Content_03 from '../components/home/content_03';
import Content_04 from '../components/home/content_04';
import Navbar from '../components/navbar';
import Scoial from '../components/social_info';

const Home = () => {
  return (
    <>
      <Scoial />
      <Navbar />
      <Hero />
      <Content_01 />
      <Content_02 />
      <Content_03 />
      <Content_04 />
      <Footer />
    </>
  );
};

export default Home;
