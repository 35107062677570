import styled from 'styled-components';

export const CheckOut = styled.div`
  width: 100%;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CheckOutContainer = styled.div`
  padding: 2rem 20px;
  width: 90%;
  height: min-content;
  background-color: #fff;
  top: -20px;
  position: relative;
  display: inline-flexbox;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  flex-wrap: wrap;

  box-shadow: 1px 7px 23px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 7px 23px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 7px 23px 0px rgba(0, 0, 0, 0.75);

  @media screen and (max-width: 539px) {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1ft);
  }
  @media screen and (max-width: 789px) {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 1ft);
    grid-template-rows: repeat(2, 1ft);
  }
`;

export const InputContainer = styled.div`
  padding: 10px;
  max-width: 250px;
  width: 250px;
  min-width: min-content;
  display: flex;
  flex-direction: row;
  height: 60px;
  background: #FFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.01);
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #4b4b4b;
  margin-left: 8px;
  margin-top: 8px;

  @media screen and (max-width: 539px) {
    margin: 8px 0;
  }
`;

export const Input = styled.select`
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  cursor: pointer;
  padding: 0 20px;
  font-weight: 400;
  background-color: white;
`;

export const InputDate = styled.input`
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  cursor: pointer;
  padding: 0 20px;
  font-weight: 400;
`;
export const Button = styled.button`
  padding: 0px 40px;
  background-color: #235347;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff;
  font-size: 1.2rem;
  height: 50px;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 8px;
`;
