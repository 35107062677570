import styled from 'styled-components';
import bg from '../../assets/images/backgroundImage.png';
export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 35vh;
  background-image: url(${bg});
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 90px;
`;
export const Img = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
