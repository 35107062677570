import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./card.css";
import axios from "axios";
import { toast } from "react-toastify";
const Card = () => {
  const [parks, setParks] = useState([]);

  useEffect(() => {
    getParkData();
  }, []);

  const getParkData = async () => {
    const bodyParameters = {
      status: 1,
    };

    await axios
      .post("https://api.wildceylonadventures.lk/api/park/all", bodyParameters)
      .then((response) => {
        setParks(response.data.output);
      })
      .error((error) => {
        toastPopup(2, error.message);
      });
  };
  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };
  let settings = {
    autoplay: false,
    dots: false,
    infinity: true,
    speed: 0,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          arrows: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  
  return (
    <div className="container mt-5 carousel">
      <Slider {...settings}>
        {parks.map((park) => {
          return (
            <div className="card-wrapper">
              <div className="card">
                <div className="card-image">
                  <img src={park.img_1_path} alt="cr" />
                </div>
                <div className="info1">
                  <div>
                    <h2>{park.park_name}</h2>
                    <p>{park.description}</p>
                    <a href={park.see_more_link} target="_blank" rel="noreferrer">
                      See More
                    </a>
                  </div>
                </div>
                <div className="details-d">
                  <h2>{park.park_name}</h2>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Card;
