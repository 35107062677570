import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
  overflow: hidden;
  padding-bottom: 100px;
  @media screen and (max-width: 500px) {
    padding-top: 5rem;
  }
`;

export const Container = styled.div`
  width: 80%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  box-shadow: 2px 10px 28px rgba(0, 0, 0, 0.25);
`;

export const Title = styled.h2`
  font-size: 64px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #235347;
  text-align: center;
  line-height: 73px;
  @media screen and (max-width: 500px) {
    font-size: 2.5rem;
    line-height: 50px;
  }
`;

export const Para = styled.p`
  margin-top: 2rem;
  font-size: 24px;
  font-weight: 400;
  line-height: 27px;
  color: #235347;
  width: 100%;
  text-align: center;
  margin-bottom: 4rem;
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
    line-height: 20px;
  }
`;

export const ContainerItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  height: max-content;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;
export const Button = styled.button`
  background-color: #2e5447;
  width: 100%;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 36px;
  line-height: 41px;
  padding-top: 6px;
  padding-bottom: 6px;

  @media screen and (max-width: 500px) {
    font-size: 1.3rem;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const Img = styled.div`
  width: 380px;
  position: relative;
  padding: 0 30px;
  @media screen and (max-width: 1000px) {
    padding: 0;
    margin-top: 30px;
  }
  @media screen and (max-width: 470px) {
    margin: 20px 30px;
  }
`;

export const Form = styled.div`
  width: 50%;
  position: relative;
  @media screen and (max-width: 949px) {
    width: 85%;
  }
`;
