import React from 'react';
import {
  ListContainer,
  List,
  ListItem,
  ListContainerItem,
} from './navigation_navElement';
import { Link, NavLink } from 'react-router-dom';

const Navigation = ({ status }) => {
  return (
    <ListContainer status={status}>
      <ListContainerItem>
        <List>
          <ListItem>
            <NavLink to="/" className='font-roboto'>Home</NavLink>
          </ListItem>
          <ListItem>
            <NavLink to="/packages" className='font-roboto'>Packages</NavLink>
          </ListItem>
          <ListItem>
            <NavLink to="/gallery" className='font-roboto'>Gallery</NavLink>
          </ListItem>
          <ListItem>
            <NavLink to="/aboutus" className='font-roboto'>About Us</NavLink>
          </ListItem>
          <ListItem>
            <NavLink to="/contactus" className='font-roboto'>Contact Us</NavLink>
          </ListItem>
        </List>
      </ListContainerItem>
    </ListContainer>
  );
};

export default Navigation;
