import { React, useState } from "react";
import {
  Content,
  Container,
  Title,
  Para,
  ContainerItem,
  Button,
  Form,
} from "../content_04/content_04Element";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Content_04 = () => {
  const [click, setClick] = useState(false);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);

  const addSupport = async () => {

    if (name !== null && email !== null && phone !== null && subject !== null && message !== null) {
      const data = {
        full_name: name,
        email_address: email,
        mobile_number: phone,
        title: subject,
        description: message,
      };


      await axios.post("https://api.wildceylonadventures.lk/api/support/add", data)
        .then((response) => {

          if (response?.data.success) {
            toastPopup(1, response.data.message);
            setName(null);
            setEmail(null);
            setPhone(null);
            setSubject(null);
            setMessage(null);
          } else {
            toastPopup(3, response.data.message);
          }
        })
        .catch((error) => {
          toastPopup(2, error.message);
        });

    } else {
      toastPopup(3, 'All fields are required!');
    }


  };
  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };
  const setFlag = () => {
    setClick(true);
  };

  const unsetFlag = () => {
    setClick(false);
  };

  return (
    <Content>
      <Container>
        <ToastContainer />
        <Title>Contact Us</Title>
        <Para>We look forward to hearing from you to cater to your needs.</Para>
        <ContainerItem>
          <Form>
            <div className="form-floating mb-3 contact-input">
              <input
                type="text"
                class="form-control"
                id="floatingInput"
                placeholder="Full Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <label
                for="floatingInput"
                style={{ color: "#212529", opacity: ".5", fontSize: "15px" }}
              >
                Name *
              </label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                placeholder="Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <label
                for="floatingInput"
                style={{ color: "#212529", opacity: ".5", fontSize: "15px" }}
              >
                email *
              </label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="floatingInput"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
              <label
                for="floatingInput"
                style={{ color: "#212529", opacity: ".5", fontSize: "15px" }}
              >
                Phone *
              </label>
            </div>
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                id="floatingInput"
                placeholder="Subject"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
              <label
                for="floatingInput"
                style={{ color: "#212529", opacity: ".5", fontSize: "15px" }}
              >
                Subject *
              </label>
            </div>
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                placeholder="Message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
              <label
                for="floatingInput"
                style={{ color: "#212529", opacity: ".5", fontSize: "17px" }}
              >
                Message *
              </label>
            </div>
            <div>
              <Button onClick={addSupport}>Send</Button>
            </div>
          </Form>
          <div className={click ? "ds" : "vs"}>
            {click ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiFillCloseCircle
                  style={{
                    color: "white",
                    position: "absolute",
                    top: "5%",
                    right: "10%",
                    fontSize: "1.8rem",
                  }}
                  onClick={unsetFlag}
                />
                <img
                  src={require("../../../assets/images/map.png")}
                  alt="map"
                  // onClick={unsetFlag}
                  width={"100%"}
                />
              </div>
            ) : (
              <img
                src={require("../../../assets/images/map.png")}
                alt="map"
                onClick={setFlag}
              />
            )}
          </div>
        </ContainerItem>
      </Container>
    </Content>
  );
};

export default Content_04;
