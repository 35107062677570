import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import Slider from "react-slick";
import { Info } from "./sliderElemenet";
import axios from "axios";
import { toast } from "react-toastify";

const Sliders = () => {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    getSlider();
  }, []);

  const getSlider = async () => {
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(
        "https://api.wildceylonadventures.lk/api/sliders/all",
        bodyParameters
      )
      .then((response) => {
        setSliders(response.data.output);
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };
  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };
  var settings = {
    infinite: true,
    dots: true,
    lazyLoad: true,
    autoplay: false,
    autoplaySpeed: 5000,
    dotsClass: "slick-dots",
    pauseOnHover: false,
    arrows: false,
  };
  return (
    <div style={{ overflow: "hidden", marginTop: "90px" }}>
      <Slider {...settings} className="slide_n">
        {sliders.map((item) => {
          return (
            <div className="slider_item">
              <img
                src={item.file_path}
                alt={"slider1"}
                width={"100%"}
                className="img-bg"
              />
              <Info>
                <img
                  src={require("../../assets/images/WildCeylon Logo_Black_BG.png")}
                  alt="sliderinfo"
                  className="info_img"
                />
                <div className="slider_info">
                  <h3 className="title font-tillana">{item.title}</h3>
                  <p
                    className="sub_title font-roboto"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </div>
              </Info>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Sliders;
