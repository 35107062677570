import styled from 'styled-components';

export const ItemContainer = styled.div`
  width: 300px;
  border-radius: 12px;
  background-color: #235347;
  height: 280px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 10px;
`;
export const Para = styled.p`
  margin-top: 2rem;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  color: #fff;
  width: 100%;
  text-align: center;
`;
export const IconContainer = styled.div`
  width: 60px;
  color: #fff;
  font-size: 3rem;
  display: flex;
  flex-direction: row;
`;
