import { GloableStyle } from "./gloableStyle";
import Home from "./pages";
import { Routes, Route } from "react-router-dom";
import Packages from "./pages/packages";
import Gallery from "./pages/gallery/gallery";
import About from "./pages/aboutus/about";
import Contact from "./pages/contactus/contact";
import PackageSelect from "./components/PackageSelect";

function App() {
  return (
    <>
      <GloableStyle />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="packages" element={<Packages />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="aboutus" element={<About />} />
        <Route path="contactus" element={<Contact />} />
        <Route path="/package" element={<PackageSelect />} />
      </Routes>
    </>
  );
}

export default App;
