import React from 'react';
import Checkout from '../checkout';
import Sliders from '../slider';

const Hero = () => {
  return (
    <>
      <Sliders />
      <Checkout />
    </>
  );
};

export default Hero;
