import { useEffect, useState } from "react";
import { Container, Img } from "./content_hero/contentHeaderElement";
import Footer from "./footer";
import Navbar from "./navbar";
import Scoial from "./social_info";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Alert } from "@mui/material";
import { height } from "@mui/system";

const PackageSelect = () => {
  const { state } = useLocation();
  const [parks, setParks] = useState([]);
  const [addons, setAddons] = useState([]);
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();

  const packageName = useState(state.pack.package_name);
  const packageId = useState(state.pack.package_id);
  const ppPrice = useState(state.pack.pp_price);
  const nationality = useState(state.pack.nationality_package_id);
  const selectedPark = useState(state.pack.park_id);
  const dateCount = useState(state.dateCount);
  const personCount = sessionStorage.getItem("personCount");
  const total = useState(
    state.pack.nationality_package_id === '1'
      ? state.pack.pp_price * personCount * dateCount[0]
      : state.pack.pp_price * personCount
  );
  const startDate =
    state.pack.nationality_package_id === "1"
      ? sessionStorage.getItem("startDate")
      : null;
  const endDate =
    state.pack.nationality_package_id === "1"
      ? sessionStorage.getItem("endDate")
      : null;

  const selectedAddons = useState(state.addon.item);

  let addon_ids = "";
  // const for success message
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    selectedAddons[0].forEach((element) => {
      addon_ids += element + ",";
    });

    parkData();
    addonData();

    console.log(ppPrice[0])
    console.log(dateCount[0])

  }, []);

  const parkData = async () => {
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post("https://api.wildceylonadventures.lk/api/park/all", bodyParameters)
      .then((response) => {
        if (response.data.success) {
          setParks(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const addonData = async () => {
    const bodyParameters = {
      status: 1,
    };
    await axios
      .post(
        "https://api.wildceylonadventures.lk/api/addons/all",
        bodyParameters
      )
      .then((response) => {
        if (response.data.success) {
          setAddons(response.data.output);
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        toastPopup(2, error.message);
      });
  };

  const addReservation = async (e) => {
    e.preventDefault();

    const addonNames = selectedAddons[0].reduce((result, item) => {
      return `${result}${item},`;
    }, "");

    const bodyParameters = {
      full_name: fullName,
      email_address: email,
      mobile_number: mobile,
      start_date: state.pack.nationality_package_id === "1" ? startDate : null,
      end_date: state.pack.nationality_package_id === "1" ? endDate : null,
      num_of_guests: personCount,
      package_id: state.pack.package_id,
      park_id:
        state.pack.nationality_package_id === "1" ? state.pack.park_id : 0,
      nationality_package_id: state.pack.nationality_package_id,
      addon_ids: addonNames,
    };

    await axios
      .post(
        "https://api.wildceylonadventures.lk/api/reservation/add",
        bodyParameters
      )
      .then((response) => {
        if (response?.data.success) {
          toastPopup(1, response.data.message);
          sessionStorage.clear();
          message();
          window.location.replace("/");
        } else {
          toastPopup(3, response.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        toastPopup(2, error.message);
      });
  };

  const toastPopup = (status, text) => {
    if (status === 1) {
      toast.success(text);
    } else if (status === 2) {
      toast.error(text);
    } else {
      toast.warn(text);
    }
  };

  // success message
  const message = () => {
    setTimeout(() => {
      setShowAlert(true);
    }, 2000);
  };

  return (
    <>
      <Scoial />
      <Navbar />
      <Container>
        <Img>
          <img
            src={require("../assets/images/WildCeylon Logo_Black_BG.png")}
            alt="log0"
            width={"200px"}
          />
        </Img>
      </Container>
      {/* bill start */}
      <div className="flex w-full justify-center items-cente bg-red-500">
        <ToastContainer />
        {showAlert && (
          <Alert
            type="success"
            message="successsfully"
            description=" Your Reservation Place Successfully...!"
          />
        )}
        <div className="flex border-4 border-black  ">
          <div className="flex">
            <form onSubmit={addReservation} id="myForm">
              <div>
                {/* <!--personal details--> */}
                <div className="py-2 text-xl">
                  <h4>Personal Details</h4>
                  <div className="row m-2">
                    <div className="col-12">
                      <label className="">Full Name</label>
                      <input
                        type="text"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white border-black boerder-2"
                        placeholder="Full Name"
                        required
                        value={fullName}
                        onChange={(e) => {
                          setFullName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <label className="">Email address</label>
                      <input
                        type="email"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white border-black boerder-2"
                        placeholder="Email Address"
                        required
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <label className="">Contact</label>
                      <input
                        type="tel"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white border-black boerder-2"
                        required
                        placeholder="Contact Number"
                        value={mobile}
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <hr className="h-1 bg-black black"></hr>
                  {/* <!--Booking details--> */}
                  <div className="px-3">
                    <h4>Booking Details</h4>

                    <div className="row mt-4">
                      <div className="col-md-6">
                        <labale className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          Package Name
                        </labale>
                        <p className="font-bold mb-2">{packageName}</p>
                      </div>

                      <div className="col-md-6">
                        {state.pack.nationality_package_id === "1" ? (
                          <labale className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                            Park Name
                          </labale>
                        ) : (
                          <labale className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                            No Specific Parks For Foriegners
                          </labale>
                        )}
                        <div
                          className={
                            state.pack.nationality_package_id === "2"
                              ? "hidden"
                              : "visible "
                          }
                        >
                          {parks.map((park) => {
                            if (parseFloat(state.pack.park_id) === park.id) {
                              return (
                                <p className="font-extrabold">
                                  {park.park_name}
                                </p>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <labale className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          Nationality Type
                        </labale>
                        <p className="font-bold mb-2">
                          {state.pack.nationality_package_id === "1"
                            ? "Local"
                            : "Foriegn"}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <labale className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          Number Of Guests
                        </labale>
                        <p className="font-bold text-black"> {personCount}</p>
                      </div>
                    </div>
                  </div>

                  <hr className="h-1 bg-black"></hr>

                  {/* <!--Package details--> */}
                  <div className="px-3">
                    <h4>Package Details</h4>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <labale className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          Check In Date
                        </labale>
                        <p className="font-bold mb-2">
                          {startDate === null
                            ? "No Selected Start Date"
                            : startDate}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <labale className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          Per Person Amount
                        </labale>
                        <p className="font-bold">
                          {state.pack.nationality_package_id === "1"
                            ? "LKR. "
                            : "$. "}
                          {parseFloat(ppPrice[0] * dateCount[0]).toLocaleString(
                            navigator.language,
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <labale className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          Check Out Date
                        </labale>
                        <p className="font-bold mb-2">
                          {endDate === null ? "No Selected End Date" : endDate}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <labale className="block uppercase tracking-wide text-gray-700 text-xs font-bold">
                          Total
                        </labale>
                        <p className="font-bold">
                          {state.pack.nationality_package_id === "1"
                            ? "LKR. "
                            : "$. "}
                          {parseFloat(total).toLocaleString(
                            navigator.language,
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr className="h-1 bg-black"></hr>
                  {/* <!--Add-ons--> */}
                  <div className="px-3">
                    <h4 className="mb-2">Add-ons</h4>
                    {state.addon.item.map((addon) => {
                      return (
                        <div className="row">
                          <div className="col-12">
                            <p className="font-bold">
                              {addon} <br />
                            </p>
                          </div>
                        </div>
                      );
                    })}
                    <p className="mb-2 mt-2">
                      Your addons prices will be added later.
                    </p>
                  </div>

                  {/* <!--Total--> */}
                  <div className="">
                    <div
                      className="row p-3"
                      style={{ justifyContent: "space-between", width: "80%" }}
                    >
                      <div className="col-6">
                        <p className="flex uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Total <br />
                        </p>
                      </div>
                      <div className="col-6">
                        <p
                          className="font-bold text-black px-4"
                          style={{ fontSize: "24px" }}
                        >
                          {state.pack.nationality_package_id === "1"
                            ? "LKR. "
                            : " $. "}
                          {parseFloat(total).toLocaleString(
                            navigator.language,
                            {
                              minimumFractionDigits: 2,
                            }
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 d-flex justify-content-center">
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#0a32e6",
                            color: "#FFF",
                            textAlign: "center",
                            fontWeight: 600,
                            padding: "2px",
                            borderRadius: "10px",
                            width: "300px",
                            margin: "4px",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-md-6 d-flex justify-content-center">
                        <button
                          style={{
                            backgroundColor: "#7a2020",
                            color: "#FFF",
                            textAlign: "center",
                            fontWeight: 600,
                            padding: "2px",
                            borderRadius: "10px",
                            width: "300px",
                            margin: "4px",
                          }}
                          onClick={() => {
                            window.location.replace("/");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* bill end */}
      <Footer />
    </>
  );
};
export default PackageSelect;
